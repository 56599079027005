import {useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import GuestSelect from 'src/components/elements/GuestSelect'
import {GuestSelectProps} from 'src/types/guestSelect'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(({palette, spacing, shadows}) => ({
  menuWrapper: {
    '& .MuiPopover-paper': {
      minWidth: 384,
      padding: spacing(0, 2.5),
      paddingRight: 0,
      background: palette.common.white,
      marginTop: '15px !important',
      boxShadow: shadows[2],
      borderRadius: 16,
      marginLeft: -18,
    },

    '& .MuiPaper-root': {
      maxHeight: '100%',
      overflowY: 'inherit',
      marginTop: spacing(3),
      '& ul': {
        '& li': {
          '& span': {
            color: palette.grey[800],
            textTransform: 'capitalize',
          },
        },
      },
    },
  },
}))

export default function GuestSelectMenu(props: {
  maximumGuests?: number
  rules?: string[] | null
  anchorEl: null | HTMLElement
  guests: GuestSelectProps
  onChange: (guests: GuestSelectProps) => void
  handleClose: () => void
}) {
  const {rules, anchorEl, guests, maximumGuests, onChange, handleClose} = props

  const classes = useStyles()

  const open = Boolean(anchorEl)

  useEffect(() => {
    if (open) {
      return
    }

    for (const key in guests) {
      if (isNaN(guests[key as keyof typeof guests])) {
        onChange({...guests, [key]: 0})
      }
    }
  }, [guests, onChange, open])

  return (
    <Menu
      className={classes.menuWrapper}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <GuestSelect
        rules={rules}
        maximumGuests={maximumGuests}
        guests={guests}
        onChangeGuest={onChange}
      />
    </Menu>
  )
}
