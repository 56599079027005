import Avatar from '@material-ui/core/Avatar'
import {pictureFormatToSrcSet} from 'src/utils/picture'
import {useUserInfo} from 'src/hooks/user'

export default function ProfilePicture(props: {isTop?: boolean}) {
  const {isTop} = props

  const userInfo = useUserInfo()

  const getProfilePicture = () => {
    if (userInfo?.picture?.url) {
      return userInfo?.picture?.url
    }

    if (isTop) {
      return '/images/avatar.svg'
    }

    return '/images/profile-dark.svg'
  }

  return (
    <div
      style={{width: '30px', height: '30px'}}
      className="bg-white rounded-full flex flex-row justify-center items-center"
    >
      <Avatar
        src={getProfilePicture()}
        srcSet={pictureFormatToSrcSet(
          userInfo?.picture?.url,
          userInfo?.picture?.formats,
          32,
        )}
        variant="square"
        imgProps={{
          width: '30px',
          height: '30px',
          sizes: '30px',
          referrerPolicy: 'no-referrer',
        }}
        style={{width: '30px', height: '30px', borderRadius: '50%'}}
      />
    </div>
  )
}
