import AppIcon from 'src/components/elements/icons/AppIcon'
import ProfilePicture from './ProfilePicture'
import BarContainer from './BarContainer'
import {useHasNewNotification} from 'src/hooks/user'
import Badge from 'src/components/elements/Badge'

export default function TopBar(props: {
  isTop?: boolean
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}) {
  const {isTop, onClick} = props
  const hasNewNotification = useHasNewNotification()

  return (
    <BarContainer
      onClick={onClick}
      style={{...(!isTop && {border: '1px solid #EAEEF5'})}}
    >
      <>
        <ProfilePicture isTop={isTop} />

        <Badge visible={hasNewNotification}>
          <div className="flex flex-col justify-center items-center ml-2">
            <AppIcon name={'menu-line-dark'} />
          </div>
        </Badge>
      </>
    </BarContainer>
  )
}
